<template>
  <div
    v-if="originName"
    class="file-container"
  >
    <div @click="handleFile(message)">
      <div
        v-if="file.mimeType.includes('image')"
        class="file-message"
        :class="[isClickableFiles ? 'cursor-pointer' : '']"
        :title="originName"
      >
        <div class="file-message-preview-image">
          <template>
            <img
              alt="Изображение"
              :src="file.url"
            >
          </template>
        </div>
        <div class="file-message-date">
          {{ createdAtFormatted }}
        </div>
      </div>

      <div
        v-else
        class="file-message"
        :class="[isClickableFiles ? 'cursor-pointer' : '']"
        :title="originName"
      >
        <div class="file-message-preview">
          <template v-if="file.mimeType.includes('image')">
            <img
              alt="Изображение"
              :src="file.url"
            >
          </template>
          <template v-else>
            <component
              :is="fileIcon"
              width="32"
              height="32"
            />
          </template>
        </div>
        <div class="file-message-content">
          <div class="file-message-content__filename">
            {{ originName }}
          </div>
          <div class="file-message-content-info">
            <span class="file-message-content-info__ext">{{ ext }}</span>
            <span class="file-message-content-info__size">{{ totalSize }}</span>
          </div>
        </div>
        <div class="file-message-date">
          {{ createdAtFormatted }}
        </div>
      </div>
    </div>
    <transition name="preview">
      <ChatPreviewFile
        v-if="showFilePreview"
        :show="showFilePreview"
        :actual-image-preview="fileUrl"
        :extention="ext"
        :active="true"
        @hide-preview="hideImagePreview"
      />
    </transition>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { format, parseISO } from '@evd3v/date-fns';

import MP4Icon from '@/assets/file/mp4.svg';
import MP3Icon from '@/assets/file/mp3.svg';
import PDFIcon from '@/assets/file/pdf.svg';
import DefaultIcon from '@/assets/file/file.svg';

import { saveFile } from '@/helpers/utils';
import { chatService } from '@/services';
import { FORMATS_FOR_PREVIEW } from '@/helpers/consts';
import ChatPreviewFile from '@/components/Chat/messages/ChatPreviewFile.vue';

export default {
  name: 'FileMessage',
  components: {
    MP3Icon,
    MP4Icon,
    PDFIcon,
    DefaultIcon,
    ChatPreviewFile,
  },
  mixins: [mixinRoles],
  props: {
    message: {
      type: Object,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFilePreview: false,
      fileUrl: null,
      FORMATS_FOR_PREVIEW,
      mimeType: null,
    };
  },
  computed: {
    originName() {
      return this.file?.originName;
    },
    totalSize() {
      const b = this.file.totalSize;
      const kb = b / 1000;
      const mb = kb / 1000;
      if (b < 1000) return ` ${b} B`;
      if (kb < 1000) return ` ${kb.toFixed(2)} KB`;
      return ` ${mb.toFixed(2)} MB`;
    },
    ext() {
      return this.file.extension.slice(1).toUpperCase();
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm');
    },
    fileIcon() {
      let fileComponent = '';

      if (this.ext === 'MP3' || this.ext === 'MP4' || this.ext === 'PDF') {
        fileComponent += this.ext;
      }
      if (!fileComponent) fileComponent += 'Default';

      return `${fileComponent}-icon`;
    },
    isClickableFiles() {
      return this.checkFeatureAccess({ name: 'Кликабельные вложения в чате', url: '/consultations' });
    },
  },
  methods: {
    async getFileFromChatService(chatId, fileName) {
      try {
        const data = await chatService.getFile({ chatAppealId: chatId, fileName });
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    async showPreviewImage(chatId) {
      const messageData = JSON.parse(this.message.payload);
      const fileName = messageData?.fileNames[0];
      if (messageData.filesInformation !== null) {
        const { mimeType } = messageData?.filesInformation[0];
        this.mimeType = mimeType;
      }
      try {
        const data = await this.getFileFromChatService(chatId, fileName);
        const blob = new Blob([data], { type: this.mimeType || 'image/jpeg' });
        const result = URL.createObjectURL(blob);
        if (result) this.fileUrl = result;
      } catch (e) {
        console.log(e);
      } finally {
        if (this.fileUrl) { this.showFilePreview = true; }
      }
    },
    async downloadFile(chatId) {
      const fileName = JSON.parse(this.message.payload)?.fileNames[0];
      const data = await this.getFileFromChatService(chatId, fileName);
      saveFile(data, { name: this.originName });
    },
    async showPdfFile(chatId) {
      const fileName = JSON.parse(this.message.payload)?.fileNames[0];
      const data = await this.getFileFromChatService(chatId, fileName);
      const blob = new Blob([data], { type: 'application/pdf' });
      saveFile(blob, { name: this.originName, open: true });
    },
    async handleFile(msg) {
      if (!this.isClickableFiles) return;

      if (FORMATS_FOR_PREVIEW.includes(this.ext)) {
        await this.showPreviewImage(msg.chatId);
      } else if (this.ext === 'PDF') {
        await this.showPdfFile(msg.chatId);
      } else {
        await this.downloadFile(msg.chatId);
      }
    },
    hideImagePreview(value) {
      this.showFilePreview = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-container{
  background: #3ca1ff86;
  border-radius: 8px;
  &.left {
    background: #e7e9e9;
  }
}
.file-message {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Muller, serif;
  margin: 5px 0;
  padding: 10px 50px 10px 10px;

  &-preview-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 42px);
    height: 100%;
    &__filename {
      font-size: 14px;
      color: #3e3e3e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-info {
      margin-top: 5px;
      font-size: 10px;
      color: #3d3d3d;
    }
  }
  &-date {
    position: absolute;
    right: 13px;
    bottom: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #96A9B3
  }
}

.cursor-pointer {
  cursor: pointer;
}

.preview-enter-active, .preview-leave-active {
  transition: all .7s;
}
.preview-enter, .preview-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
