<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
      opacity="0.6"
    />

    <div>
      <div
        v-if="messages.length"
        class="p-4"
      >
        <div class="messages-date">
          <span class="messages-date-time">
            {{ formatDate(isMessagesChatDate) }}
          </span>
        </div>
        <div
          v-if="!isLoading"
          class="messages-wrapper"
        >
          <MessageContainer
            v-for="message in filterdMessages"
            :key="message.id"
            :message="message"
            :files="files"
            @input="checkedMessage(message)"
          />
        </div>
      </div>
      <div
        v-else
        class="w-100 bg-white shadow-lg rounded p-4 font-weight-bold "
      >
        Нет данных
      </div>
    </div>
    <template
      #modal-footer
    >
      <div
        class="flex w-full justify-content-between"
      >
        <div>
          <b-button
            v-if="checkFeatureAccess({ name: 'Кнопка Протокол в Чате консультации', url: '/consultations' })"
            variant="success"
            :type="$const.PRIMARY_BUTTON"
            @click="openProtocolsModal"
          >
            Протоколы
          </b-button>
        </div>
        <div class="flex gap-2">
          <b-button
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            :disabled="!checkedMessageArray.length"
            @click="clearCheckbox"
          >
            Отменить
          </b-button>
          <b-button
            v-if="checkFeatureAccess({ name: 'Чат консультации - Кнопка удаления в Чате', url: '/consultations' })"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :disabled="!checkedMessageArray.length"
            @click="openDeleteModal"
          >
            Удалить
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { format, addHours } from '@evd3v/date-fns';
import MessageContainer from '@/components/Consultations/MessageContainer';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'ConsultationChatModal',
  components: {
    MessageContainer,
  },
  mixins: [mixinRoles],

  props: {
    title: {
      type: String,
      default: 'Чат консультации',
    },
    consultationId: {
      type: [String, Number],
      default: '',
    },
    personId: {
      type: [String, Number],
      default: null,
    },
    folderName: {
      type: [String, Number],
      default: '',
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      messages: [],
      files: [],
      checkedMessageArray: [],
    };
  },
  computed: {
    filterdMessages() {
      return this.messages;
    },
    isMessagesChatDate() {
      return this.messages.map((i) => i.updatedAt)[0];
    },
  },
  async created() {
    this.isLoading = true;
    await Promise.all([this.getMessagesChat(), this.getFilesChat()]);
    this.isLoading = false;
  },
  destroyed() {
    this.files.forEach((file) => {
      if (file.url) {
        URL.revokeObjectURL(file.url);
      }
    });
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy');
    },
    formatDateTime(date) {
      const dateAdded = addHours(new Date(date), 0);
      return format(dateAdded, 'HH:mm');
    },
    clearCheckbox() {
      this.messages = this.messages.map((message) => (message.checked ? { ...message, checked: false } : message));
      this.checkedMessageArray = [];
    },
    checkedMessage(message) {
      if (message.checked) {
        this.checkedMessageArray.push(message);
      } else {
        const index = this.checkedMessageArray.indexOf(message);
        if (index !== -1) {
          this.checkedMessageArray.splice(index, 1);
        }
      }
    },
    async getMessagesChat() {
      this.messages = await this.$store.dispatch(this.$types.CONSULTATION_CHAT_FETCH, this.consultationId);
      this.messages = this.messages.map((message) => ({
        ...message,
        checked: false,
      })).reverse();
    },
    async getFilesChat() {
      const files = await this.$store.dispatch(this.$types.CONSULTATION_FILES_FETCH, this.folderName);

      const filesData = await Promise.all(files.map((file) => this.$store.dispatch(this.$types.CONSULTATION_FILE_FETCH, file.name)));
      this.files = files.map((file, i) => {
        const url = URL.createObjectURL(filesData[i]);
        return ({ ...file, url });
      });
    },
    async deleteMessageFromChat() {
      this.isLoading = true;

      await Promise.all(this.checkedMessageArray.map(async (item) => {
        await this.$store.dispatch(this.$types.CONSULTATION_CHAT_MESSAGE_DELETE, item.id);
      }));

      this.checkedMessageArray = [];
      await this.getMessagesChat();
      this.isLoading = false;
    },
    openDeleteModal() {
      uiService.showModal(MODALS.AGREE_MODAL, {
        name: 'AgreeModal',
        props: {
          title: 'Удаление чата',
        },
        actions: {
          delete: async () => {
            try {
              await this.deleteMessageFromChat();
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
    openProtocolsModal() {
      uiService.showModal(MODALS.PROTOCOLS_MODAL, {
        name: 'ProtocolsModal',
        props: {
          personId: this.personId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  padding-bottom: 50px;
  transition: all 0.3s;
  height: calc(100vh - 360px);
  position: relative;
  background: white;
  padding: 16px;
}
.messages-date{
  text-align: center;
  top: 0px;
}
</style>
